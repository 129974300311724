let gLocatorMap;
let gLocatorMapMarkers = [];
let gAutocomplete;
const defaultMapCenter = {lat: 52, lng: 5};
const defaultMapZoom = 7.5;
const geoSearchInputId = 'js-post-search-location-input';
const geoSearchAutocompletedInputSelector = '#js-post-search-location-autocompleted-input';
const geoLoadMoreSelector = '#js-post-location-load-more';
const geoResultsFoundSelector = '.t-post-geolocation-search__result-found';
const geoSearchFiltersSelector = '.js-toggle-post-geolocation-filter';
const geoSearchFilterItem = '.js-post-geolocation-filter-item';
const geoSearchResetFilters = '.js-reset-term-filters';


function initMapsLoaders() {
    let postLocatorElement = document.getElementById("js-post-locator-map");

    if (postLocatorElement === null) {
        return;
    }

    // Short namespaces can be used.
    gLocatorMap = new google.maps.Map(postLocatorElement, {
        center: defaultMapCenter,
        zoom: defaultMapZoom,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false
    });


    gAutocomplete = new google.maps.places.Autocomplete(document.getElementById(geoSearchInputId), {
        types: ["geocode", "establishment"],
        componentRestrictions: {country: "nl"},
        fields: ["formatted_address", "place_id", "geometry", "name"],
    });
    gAutocomplete.addListener("place_changed", geoPlaceChanged);

    let $geoLocationSearch = $('#' + geoSearchInputId);
    let $geoLocationLoadMore = $(geoLoadMoreSelector);
    let $geoSearchFilters = $(geoSearchFiltersSelector);
    let $geoSearchFilterItem = $(geoSearchFilterItem);
    let $geoResetFilters = $(geoSearchResetFilters);

    $geoLocationSearch.on('search', function (evt) {

        if ($(evt.currentTarget).val() === '') {
            clearGeoLocationSearchResults();
            removeGeoLocationMapMarkers();
            setGeoBounds();
        }
    });

    $geoLocationSearch.on('keyup', function (evt) {

        setTimeout(function () {

            let formattedVal = '';
            let e = document.querySelector(".pac-container");
            if (e && e.hasChildNodes()) {
                let n = e.children.item(0).children.item(1).innerText
                    , o = e.children.item(0).children.item(2).innerText;
                formattedVal = n + ", " + o;
            }
            updateFormattedGeoLocation(formattedVal);
        }, 200)

    });

    $geoSearchFilters.on('click', function () {
        $(this).parent().toggleClass('is-open');
    });

    $geoSearchFilterItem.on('click', function () {
        var filterValue = $(this).val();
        var filterType = $(this).data('type');

        $geoSearchFilters.parent().removeClass('is-open');

        addFilterTermToAddressBar(filterType, filterValue);

        doGeoLocationSearch(
            $(geoSearchAutocompletedInputSelector).attr('data-lat'),
            $(geoSearchAutocompletedInputSelector).attr('data-lng'),
            0,
            getSelectedFilters()
        );

    });

    $geoLocationLoadMore.on('click', function (evt) {

        let offset = $('.t-post-geolocation-search__search-results .c-post-geolocation-search-result-card').length;

        doGeoLocationSearch(
            $(geoSearchAutocompletedInputSelector).attr('data-lat'),
            $(geoSearchAutocompletedInputSelector).attr('data-lng'),
            offset,
            getSelectedFilters()
        );
    });

    $geoResetFilters.on('click', function (e) {
        e.preventDefault();
        $geoSearchFilterItem.each(function () {
            if (($(this).is(':checked'))) {
                removeFilterTermFromAddressBar($(this).data('type'));
                $(this).prop("checked", false);
            }
        });

        doGeoLocationSearch(
            $(geoSearchAutocompletedInputSelector).attr('data-lat'),
            $(geoSearchAutocompletedInputSelector).attr('data-lng'),
            0,
            []
        );
    });

    setGeoLocationMapMarkers();
    setGeoBounds();
}

window.initMapsLoaders = initMapsLoaders;

function clearGeoLocationSearchResults() {
    $('.js-post-location-results').html('');
    updateGeoLocationSearchResultCount(false);
    setGeoLoadMoreButtonVisibility(false);
    handleGeoLocationSearchTermUpdate('');
}

function updateGeoLocationSearchResultCount(active = true, count = 0) {
    $(geoResultsFoundSelector).attr('data-active', active ? 1 : 0);
    $(".t-post-geolocation-search__result-found .js-geolocation-result-count").html(count);
}

function setGeoLoadMoreButtonVisibility(hasNext = false) {
    $(geoLoadMoreSelector).attr('data-has-next', (hasNext) ? 1 : 0);
}

function updateGeoLocationSearchResults(html, offset, total, hasNext) {
    let $postLocationResultsElement = $('.js-post-location-results');

    if (offset > 0) {
        $postLocationResultsElement.append(html);
    } else {
        updateGeoLocationSearchResultCount(true, total);
        $postLocationResultsElement.html(html);
    }
    setGeoLoadMoreButtonVisibility(hasNext);
}

function geoPlaceChanged() {

    var place = gAutocomplete.getPlace();
    if (place !== null && place.geometry) {
        handleGeoLocationSearchTermUpdate(place.formatted_address, place.geometry.location.lat(), place.geometry.location.lng());
        doGeoLocationSearch(place.geometry.location.lat(), place.geometry.location.lng(), 0, getSelectedFilters());
    }
}

function updateFormattedGeoLocation(val, lat = '', lng = '') {
    $(geoSearchAutocompletedInputSelector).attr('data-lat', lat);
    $(geoSearchAutocompletedInputSelector).attr('data-lng', lng);
    $(geoSearchAutocompletedInputSelector).val(val);
}

function handleGeoLocationSearchTermUpdate(formattedLocation, lat = null, lng = null) {
    updateFormattedGeoLocation(formattedLocation, lat, lng);
    updateAddressBarWithGeoSearchTerm(formattedLocation);
    // update URL: Set searchterm
}

function updateAddressBarWithGeoSearchTerm(term) {

    // urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('location');

    if (term !== '') {
        urlParams.set('location', term);
    }
    window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);
}

function addFilterTermToAddressBar(filterType, termId) {

    // urlParams = new URLSearchParams(window.location.search);
    urlParams.delete(filterType);

    if (termId !== '' && filterType !== '') {
        urlParams.set(filterType, termId);
    }
    window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);
}

function removeFilterTermFromAddressBar(filterType) {

    // urlParams = new URLSearchParams(window.location.search);
    urlParams.delete(filterType);
    window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);
}


function doGeoLocationSearch(lat, lng, offset = 0, selectedFilters = []) {

    getPostGeoLocationSearchResults(lat, lng, offset, selectedFilters, (response) => {

        let requiredProps = ["html", "total", "hasNext"];
        if (requiredProps.every(function (x) {
            return x in response;
        })) {
            updateGeoLocationSearchResults(response.html, offset, response.total, response.hasNext);
        }
        removeGeoLocationMapMarkers();
        setGeoLocationMapMarkers();
        setGeoBounds();
    });
}

function getPostGeoLocationSearchResults(lat, lng, offset = 0, selectedFilters, successCallback) {
    // $('.js-loading').addClass('is-loading');
    $('.js-t-post-geolocation-search-loading').addClass('is-loading');
    $.ajax({
        url: linku_ajax_url,
        type: 'post',
        data: {
            action: 'post_geo_location_search',
            lat: lat,
            lng: lng,
            offset: offset,
            selectedFilters: selectedFilters,
        },
        dataType: 'json',
        success: successCallback,
        error: function (response) {
            console.log('error', response);
            // $searchResults.removeClass('is_loading');
        },
        complete: function (response) {
            $('.js-t-post-geolocation-search-loading').removeClass('is-loading');
        }
    });
}

function removeGeoLocationMapMarkers() {
    gLocatorMapMarkers.forEach(function (marker) {
        marker.setMap(null);
    });
    gLocatorMapMarkers = [];
}

function setGeoBounds() {

    if (gLocatorMapMarkers.length === 0) {
        gLocatorMap.setCenter(defaultMapCenter);
        gLocatorMap.setZoom(defaultMapZoom);
        return;
    }

    let bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < gLocatorMapMarkers.length; i++) {
        bounds.extend(gLocatorMapMarkers[i].getPosition());
    }
    gLocatorMap.fitBounds(bounds);
    gLocatorMap.setCenter(bounds.getCenter());

}

function setGeoLocationMapMarkers() {
    var lastOpenedInfoWindow;

    let $resultCards = $('.js-post-location-results .js-result-card');

    $resultCards.each(function (index, element) {
        this.setAttribute('data-index', index);
        var latitude = parseFloat(element.getAttribute('data-latitude'));
        var longitude = parseFloat(element.getAttribute('data-longitude'));
        var location = {
            lat: latitude,
            lng: longitude
        };

        var marker = new google.maps.Marker({
            position: location,
            map: gLocatorMap,
        });

        gLocatorMapMarkers.push(marker);

        marker.addListener('click', function () {
            var employerDocument = new DOMParser().parseFromString(element.outerHTML, "text/html");
            var $content = $(employerDocument.firstChild).find('.js-result-card');
            // $content.find('.js-more-info').remove();
            var markerContent = $content[0];

            markerContent.classList.add('marker-modal');
            var markerid = markerContent.getAttribute('id');
            markerContent.setAttribute('id', markerid + '-marker');

            var infoWindow = new google.maps.InfoWindow({
                content: markerContent.outerHTML,
                maxWidth: 800,
                maxHeight: 100
            });

            if (lastOpenedInfoWindow) {
                lastOpenedInfoWindow.close();
            }

            lastOpenedInfoWindow = infoWindow;
            infoWindow.open(gLocatorMapMarkers, marker);
            scrollIntoPostLocationMarkerView(markerid);
        });
    });
}

function scrollIntoPostLocationMarkerView(id) {
    const pulse = [
        {outline: "solid 0px var(--color-primary)"},
        {outline: "solid 5px var(--color-primary)"},
        {outline: "solid 0px var(--color-primary)"},
    ];

    const pulseTiming = {
        duration: 2000,
        iterations: 2,
    };
}

function getSelectedFilters() {
    let $selectedFilters = [];
    $(geoSearchFilterItem).each(function () {
            if (($(this).is(':checked'))) {
                $selectedFilters.push($(this).val());
            }
        }
    );
    return $selectedFilters;
}

